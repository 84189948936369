<template>
  <v-btn block color="primary" @click="openImport" class="">
    <v-icon left dark>mdi-file-upload </v-icon>
    <p class="pa-0 ma-0 pt-1" align="center">
      {{ $t('teacher.import-classList') }}
    </p>
  </v-btn>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class OpenImportButton extends Vue {
  @Emit()
  openImport() {
    console.log();
  }
}
</script>

<style scoped lang="scss"></style>
