<template>
  <v-btn
    block
    dark
    color="black"
    class="delete-all-button"
    @click="openDialog"
    :loading="loading"
  >
    <v-icon left dark>mdi-delete </v-icon>
    <p class="pa-0 ma-0 mr-2 mt-1" align="center">
      {{ $t('client.delete-students') }}
    </p>
    <DeleteAllDialog
      :show="dialog"
      @confirm-delete="deleteAll"
      @close="dialog = false"
    ></DeleteAllDialog>
  </v-btn>
</template>

<script lang="ts">
import { UserDto } from '@/api/types';
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import ClassListTemplate from '../pdf-templates/ClassListTemplate.vue';
import DeleteAllDialog from './DeleteAllDialog.vue';

@Component({
  components: { ClassListTemplate, DeleteAllDialog },
})
export default class PrintClassListButton extends Vue {
  @Prop() students!: UserDto[];

  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  loading = false;
  dialog = false;

  openDialog(): void {
    this.dialog = true;
  }

  async deleteAll(): Promise<void> {
    try {
      this.loading = true;

      if (this.students.length !== 0) {
        await this.schoolModule.deleteAllStudentsOfClass({
          schoolId: this.schoolModule.schoolId?.toString() || '',
          grade: this.schoolModule.selectedClass?.name || '',
        });
        this.deleted();
        this.snackbarModule.showMessage({
          message: this.$i18n.t('snackbar.delete-students.success').toString(),
          isSuccess: true,
        });
      }
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.delete-students.error').toString(),
        isSuccess: false,
      });
    } finally {
      this.loading = false;
    }
  }

  @Emit()
  deleted(): void {
    console.log();
  }
}
</script>

<style scoped lang="scss"></style>
