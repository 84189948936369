<template>
  <v-col
    cols="12"
    sm="11"
    md="10"
    lg="10"
    xl="10"
    class="align-self-start d-flex flex-column"
    align="center"
  >
    <v-col
      cols="12"
      class="csv-info justify-start mb-3 grey--text text--darken-3"
    >
      <p
        class="csv-info__text text-body-1"
        v-html="$t(`${title}.dashboard.csv.info`)"
      ></p>
      <p
        class="csv-info__text text-body-1"
        v-html="$t(`${title}.dashboard.csv.1`)"
      ></p>

      <p
        class="csv-info__text text-body-1"
        v-html="$t(`${title}.dashboard.csv.2`)"
      ></p>
      <v-img
        class="mb-4"
        :src="csvSample"
        max-height="250"
        max-width="300"
      ></v-img>
      <p
        class="csv-info__text text-body-1"
        v-html="$t(`${title}.dashboard.csv.3`)"
      ></p>
      <v-img
        class="csv-info__text mb-4"
        :src="saveAsCsvSample"
        max-height="250"
        max-width="300"
      ></v-img>
      <p
        class="csv-info__text text-body-1"
        v-html="$t(`${title}.dashboard.csv.4`)"
      ></p>
      <p
        class="csv-info__text font-weight-bold subtitle-1 red--text ma-0"
        v-html="$t(`${title}.dashboard.csv.error`)"
      ></p>
      <p
        class="csv-info__text text-body-1"
        v-html="$t(`${title}.dashboard.csv.errorInfo`)"
      ></p>
    </v-col>

    <v-col cols="11" class="d-flex justify-space-between pa-0 ma-0">
      <v-col cols="12" md="6" class="pa-0">
        <v-file-input
          ref="fileupload"
          label="Ihre CSV Datei"
          :rules="rules"
          accept=".csv"
          outlined
          dense
          @change="uploadFile"
          @click:clear="resetImport"
          v-model="file"
          :error-messages="messages"
        ></v-file-input>
      </v-col>
      <v-col cols="12" md="5" class="d-flex pa-0">
        <v-btn
          block
          @click="importData"
          :loading="isLoading"
          :disabled="!file || file.type !== 'text/csv' || messages.length !== 0"
          color="primary"
        >
          <v-icon left dark>mdi-file-upload </v-icon>
          <p class="pa-0 ma-0 pt-1" align="center">
            {{ $t('teacher.import-classList') }}
          </p>
        </v-btn>
      </v-col>
    </v-col>
  </v-col>
</template>

<script lang="ts">
import { CreateUserDto, Role } from '@/api/types';
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import UserModule from '@/store/modules/UserModule';
import { ImportStudentDto } from '@/types';
import { getDefaultPassword, getNameAbbr } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import Papa from 'papaparse';
import languageEncoding from 'detect-file-encoding-and-language';

@Component({
  components: {},
})
export default class CsvImportStudents extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  @Prop() grade!: string;
  @Prop() schoolId!: string;
  @Prop() ids!: string[];
  @Prop() isTherapist!: boolean;
  @Prop() show!: boolean;

  csv: ImportStudentDto[] = [];
  isLoading = false;
  tableHeaders = ['ID', 'Vorname', 'Nachname'];
  students: CreateUserDto[] = [];
  file: any = null;

  @Watch('show')
  reset() {
    if (!this.show) {
      this.file = null;
      if (this.$refs.fileupload) {
        (this.$refs.fileupload as any).reset();
      }
    }
  }

  rules = [
    (value: any) => {
      return value?.type === 'text/csv' || 'Nur CSV-Dateien erlaubt!';
    },
  ];
  messages: string[] = [];

  checkHeaders(headers: string[]): boolean {
    headers = headers.map((header) => header.trim());

    if (
      !headers ||
      headers.length === 0 ||
      headers === null ||
      headers === undefined
    )
      return false;
    const validHeaders = this.isTherapist
      ? ['ID', 'Vorname', 'Nachname', 'Schulstufe']
      : ['ID', 'Vorname', 'Nachname'];

    return (
      validHeaders.length === headers.length &&
      validHeaders.every((header) => headers.includes(header))
    );
  }

  resetImport() {
    this.file = null;
    this.messages = [];
    this.csv = [];
  }

  async uploadFile() {
    // INFO reset error messages before checks
    this.messages = [];

    if (!this.file || this.file.type !== 'text/csv') {
      this.messages = [];
      return;
    }

    languageEncoding(this.file).then((fileInfo) => {
      const { encoding } = fileInfo;

      if (encoding !== 'UTF-8') {
        this.messages.push(
          this.$i18n.t('snackbar.classList.import.formatError').toString(),
        );
        return;
      }
    });

    const reader = new FileReader();
    reader.readAsText(this.file);
    const studentsToUpload: ImportStudentDto[] = [];

    reader.onload = (event: any) => {
      let headers: any[] = [];

      const parseResult = Papa.parse(reader.result as string);
      headers = parseResult.data?.[0] as string[];
      let pupils = [...parseResult.data];
      pupils.shift();

      if (!this.checkHeaders(headers)) {
        this.messages.push(
          this.$i18n.t('snackbar.classList.import.headerError').toString(),
        );
        return;
      }
      pupils.forEach((p: any) => {
        const arrayInfoLength = this.isTherapist ? 4 : 3;
        if (p.length !== arrayInfoLength) return;
        const pupil = p;
        const student: ImportStudentDto = this.isTherapist
          ? {
              ID: pupil[0],
              Vorname: pupil[1].trimEnd(),
              Nachname: pupil[2].trimEnd(),
              Schulstufe: pupil[3].trimEnd(),
            }
          : {
              ID: pupil[0],
              Vorname: pupil[1].trimEnd(),
              Nachname: pupil[2].trimEnd(),
            };
        studentsToUpload.push(student);
        if (studentsToUpload.length !== 0) {
          this.csv = studentsToUpload;
        }
      });
    };

    reader.onerror = function () {
      console.log('CSV READER ERROR', reader.error);
    };
  }

  get csvSample(): string {
    return this.isTherapist
      ? require('@/assets/images/save-therapist-csv.png')
      : require('@/assets/images/csv_sample.png');
  }

  get saveAsCsvSample(): string {
    return require('@/assets/images/save_as_csv.png');
  }

  get title(): string {
    return this.isTherapist ? 'therapist' : 'teacher';
  }

  async importData(): Promise<void> {
    this.isLoading = true;
    const importIds = this.csv.map((pupil) => pupil.ID);
    const setOfImportIds = new Set(importIds);
    try {
      // if ids not unique in csv
      if (importIds.length != setOfImportIds.size) {
        throw Error('imported ids must be unique!');
      }

      this.csv!.forEach((person) => {
        // check if id already used
        if (this.ids.includes(person.ID)) {
          throw Error('id already in use');
        }

        const DEFAULT_PASSWORD = getDefaultPassword(
          person.Vorname,
          person.Nachname,
        );
        const nameAbbr = getNameAbbr(
          person.Vorname,
          person.Nachname,
          this.isTherapist,
        );
        const schoolSuffix = this.isTherapist
          ? this.schoolModule.schoolId
          : this.schoolModule.schoolSuffix;
        const studentUsername = this.isTherapist
          ? `${nameAbbr}_${schoolSuffix}`
          : `${nameAbbr}${schoolSuffix}`;

        const student: CreateUserDto = {
          username: studentUsername,
          studentId: parseInt(person.ID),
          firstname: person.Vorname,
          lastname: person.Nachname,
          password: DEFAULT_PASSWORD,
          studentPassword: DEFAULT_PASSWORD,
          role: Role.student,
          schoolId: this.schoolId,
          grade: this.isTherapist ? person.Schulstufe : this.grade,
        };
        this.students.push(student);
      });
      await this.schoolModule.importStudents({
        students: this.students,
        className: this.grade,
      });
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.classList.import.success').toString(),
        isSuccess: true,
      });
      this.$emit('importStudents', this.students);
    } catch (error: any) {
      (this.$refs.fileupload as any).reset();
      if (error?.message === 'id already in use') {
        this.messages.push(
          this.$i18n.t('snackbar.classList.import.already').toString(),
        );
        this.snackbarModule.showMessage({
          message: this.$i18n.t('snackbar.classList.import.already').toString(),
          isSuccess: false,
        });
      } else if (error?.message === 'imported ids must be unique!') {
        this.messages.push(
          this.$i18n.t('snackbar.classList.import.unique').toString(),
        );
        this.snackbarModule.showMessage({
          message: this.$i18n.t('snackbar.classList.import.unique').toString(),
          isSuccess: false,
        });
      } else {
        this.messages.push(
          this.$i18n.t('snackbar.classList.import.error').toString(),
        );
        this.snackbarModule.showMessage({
          message: this.$i18n.t('snackbar.classList.import.error').toString(),
          isSuccess: false,
        });
      }
    } finally {
      this.isLoading = false;
      this.file = null;
      this.messages = [];
      this.students = [];
      this.csv = [];
    }
  }
}
</script>

<style lang="scss">
.vue-csv-mapping {
  display: none;
}

.csv-import {
  width: 80%;
}

.progress-circle {
  width: 100%;
}

.form-group .form-control-file {
  background-color: $secondary;
}

.form-control-file #file-upload-button {
  background-color: red !important;
}

.csv-info {
  border-radius: 15px;
  background-color: white;

  &__text {
    background-color: white;
  }
}
</style>
