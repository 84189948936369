<template>
  <v-row cols="12" class="ma-0">
    <v-tooltip top allow-overflow open-delay="1000">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="openDialog"
          color="secondary"
          v-bind="attrs"
          v-on="on"
          class="ma-0 pa-0"
          block
        >
          <v-icon color="black" class="pr-2"> mdi-book-remove-multiple</v-icon>
          <p class="ma-0 mb-n1 black--text">
            {{ $t('manageTests.delete.buttonAll') }}
          </p>
        </v-btn>
      </template>
      <span>{{ $t('manageTests.delete.infoAll') }}</span>
    </v-tooltip>
    <DeleteTestDialog
      :show="showDialog"
      :grade="grade"
      @confirm-delete="deleteTestResults"
      @close="closeDialog"
    />
  </v-row>
</template>

<script lang="ts">
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import UserModule from '@/store/modules/UserModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import DeleteTestDialog from './DeleteTestDialog.vue';

@Component({
  components: { DeleteTestDialog },
})
export default class DeleteAllTestButton extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  showDialog = false;

  openDialog() {
    if (this.grade !== '') {
      this.showDialog = true;
    } else {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('manageTests.delete.noClass').toString(),
        isSuccess: false,
      });
    }
  }

  closeDialog() {
    this.showDialog = false;
  }

  get grade() {
    return this.schoolModule.selectedClass?.name ?? '';
  }

  async deleteTestResults() {
    try {
      await this.schoolModule.deleteAllTestsFromClass({
        schoolId: this.schoolModule.schoolId!,
        className: this.grade,
      });

      await this.schoolModule.getClassStudents(this.grade);

      this.snackbarModule.showMessage({
        message: this.$i18n.t('manageTests.delete.success.all').toString(),
        isSuccess: true,
      });
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('manageTests.delete.error').toString(),
        isSuccess: false,
      });
    }
  }
}
</script>

<style scoped lang="scss"></style>
