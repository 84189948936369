<template>
  <v-dialog v-model="show" max-width="500px" persistent>
    <v-card>
      <v-card-title class="justify-center">
        <v-icon color="warning">mdi-alert</v-icon>
        <p class="text-h5 font-weight-bold mb-0 mx-2">{{ $t('attention') }}!</p>
        <v-icon color="warning">mdi-alert</v-icon>
      </v-card-title>
      <v-card-text>
        <v-row class="d-flex flex-column justify-center align-center my-3">
          <p class="text-h6 font-weight-bold">
            {{ $t('snackbar.delete-students.warning') }}
          </p>
          <p class="text-body-1 text-center my-2 mb-4">
            {{ $t('snackbar.delete-students.warning2') }}
          </p>

          <p class="body-1 text-uppercase text-center font-weight-bold">
            {{ $t('point-of-no-return') }}
          </p>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">
          <p class="ma-0">{{ $t('cancel') }}</p>
        </v-btn>
        <v-btn
          id="delete-student-button"
          color="error"
          :loading="loading"
          @click="confirmDelete"
        >
          <p class="ma-0">{{ $t('delete') }}</p>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';

@Component
export default class DeleteAllDialog extends Vue {
  @Prop() show!: boolean;
  loading = false;

  @Emit()
  confirmDelete(): void {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.close();
    }, 1000);
    console.log();
  }

  @Emit()
  close(): void {
    console.log();
  }
}
</script>

<style scoped lang="scss"></style>
