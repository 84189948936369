<template>
  <v-btn block color="primary" @click="openAddDialog">
    <v-icon class="mr-2">mdi-account-plus</v-icon>
    <p class="ma-0 mt-1">
      {{
        isTherapist ? $t('therapist.add-patient') : $t('teacher.add-student')
      }}
    </p>
  </v-btn>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class AddAccountButton extends Vue {
  @Prop()
  isTherapist!: boolean;

  @Emit()
  openAddDialog() {
    console.log('');
  }
}
</script>

<style scoped lang="scss"></style>
