<template>
  <v-col v-if="isTherapist" cols="10" md="10" lg="8" xl="8" class="mt-4">
    <p class="headline text-center primary--text mb-0">
      {{ $t('therapist.patients') }}
    </p>
  </v-col>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class StudentDashboardHeadline extends Vue {
  @Prop({ default: false }) isTherapist?: boolean;
}
</script>

<style scoped lang="scss">
.expansion {
  background-color: $background;

  &__button {
    background-color: $background;
    width: 100% !important;
    padding-left: 35%;
    padding-right: 35%;
    margin: 0;
  }

  * {
    background-color: $background;
  }
}
</style>
