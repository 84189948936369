<template>
  <section class="pdf-content">
    <section class="pdf-content__items pdf-item">
      <v-row class="pdf-list">
        <v-col
          cols="6"
          class="name-tag"
          v-for="student in students"
          :key="student._id"
        >
          <p class="norddruck pdf-content__url">
            {{ url }}
          </p>
          <!-- <p class="norddruck pdf-content__name">
            {{ student.firstname }} {{ student.lastname }}
          </p> -->
          <p class="norddruck pdf-content__title">Benutzername:</p>
          <p class="norddruck h6 pdf-content__text">
            {{ student.username }}
          </p>
          <p class="norddruck pdf-content__title">Passwort:</p>
          <p class="norddruck pdf-content__text">
            {{ student.studentPassword }}
          </p>
        </v-col>
      </v-row>
    </section>
  </section>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { UserDto } from '../../../../api/types';

@Component({})
export default class ClassListTemplate extends Vue {
  @Prop() students!: UserDto[];
  @Prop() grade!: string;

  get leo(): string {
    return require('@/assets/images/lion_small.png');
  }

  get url(): string {
    return 'lesikus.alphaport.at';
  }
}
</script>

<style scoped lang="scss">
.pdf-content {
  margin-top: 9px;
  margin-left: 8px;

  &__url {
    font-size: 16px;
    margin-bottom: 23px;
  }

  &__name {
    margin-bottom: 22px;
  }

  &__title {
    margin-bottom: 5px;
    font-size: 12px;
  }

  &__text {
    font-size: 26px;
    margin-bottom: 18px;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

.pdf-list {
  display: flex;
  justify-content: flex-start;
  text-align: start;
  width: 100%;
  height: 100%;
}

.name-tag {
  border: 1px solid black;
  padding: 52px 60px 52px 42px;
  height: 25%;
}
</style>
