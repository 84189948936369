<template>
  <v-dialog v-model="show" max-width="500px" persistent>
    <v-card>
      <v-card-title class="justify-center">
        <p class="text-h5 font-weight-bold">{{ $t('attention') }}!</p>
      </v-card-title>
      <v-card-text>
        <v-row class="d-flex flex-column justify-center align-center my-3">
          <p class="body-1">Möchten Sie</p>
          <p class="text-h5 font-weight-bold">
            {{ user?.firstname }} {{ user?.lastname }}
          </p>
          <p class="body-1">wirklich löschen?</p>

          <p class="body-1">
            {{ $t('point-of-no-return') }}
          </p>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onClose">
          <p class="ma-0">{{ $t('cancel') }}</p>
        </v-btn>
        <v-btn
          id="delete-student-button"
          color="error"
          :loading="loading"
          @click="deleteUser"
        >
          <p class="ma-0">{{ $t('delete') }}</p>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { UserDto } from '../../../../api/types/api';

@Component
export default class DeleteDialog extends Vue {
  @Prop() show!: boolean;
  @Prop() user!: UserDto;
  loading = false;

  deleteUser(): void {
    this.loading = true;
    this.onDelete(this.user);
    setTimeout(() => (this.loading = false), 800);
  }

  @Emit()
  onClose(): void {
    console.log();
  }

  @Emit()
  onDelete(user: UserDto) {
    return user;
  }
}
</script>

<style scoped lang="scss"></style>
