<template>
  <v-col cols="12" sm="12" md="10" lg="10" xl="10" class="mb-12">
    <v-row class="d-flex justify-center ma-0">
      <v-col cols="10">
        <GeneralInfoPanels :isTherapist="isTherapistOrParent" />
      </v-col>
      <v-col cols="12" class="mt-5">
        <AddPatientAccount
          v-if="isTherapistOrParent"
          :dialog="showAddDialog"
          @added-student="added"
          @close-add-dialog="closeAddDialog"
        />
        <AddStudentAccount
          v-else
          :dialog="showAddDialog"
          :grade="currentGrade"
          :students="users"
          :ids="ids"
          @added-student="added"
          @close-add-dialog="closeAddDialog"
        />
        <ImportDialog
          :isTherapist="isTherapistOrParent"
          :dialog="showImportDialog"
          :ids="ids"
          @imported="added"
          @close="closeImportDialog"
        />
      </v-col>
    </v-row>
    <v-row v-if="isSchool" justify="center" class="align-center">
      <p class="text-h5 text-sm-h4 text-md-h4 text-center primary--text mb-5">
        {{ $t('teacher.classList') + ' ' + currentGrade }}
      </p>
    </v-row>
    <p
      v-else
      class="text-h5 text-sm-h4 text-md-h4 text-center primary--text mb-5"
    >
      {{ $t('therapist.patientsList') }}
    </p>
    <v-card class="student-table mb-12 elevation-0">
      <v-card-title>
        <v-col cols="12" class="d-flex flex-wrap">
          <v-col
            cols="12"
            class="table__buttons d-flex justify-center align-start flex-wrap mb-6"
          >
            <v-col v-if="isSchool && hasGrade" cols="12">
              <p class="text-center grey--text text--darken-2">
                {{ $t('teacher.add-info') }}
              </p>
            </v-col>
            <v-col v-if="isSchool && !hasGrade" cols="12">
              <p class="text-center grey--text text--darken-2">
                {{ $t('teacher.no-grade-info') }}
              </p>
            </v-col>

            <v-col v-if="isSchool && hasGrade" cols="12" sm="12" md="5" lg="4">
              <AddAccountButton
                id="add-student"
                :isTherapist="isTherapistOrParent"
                @open-add-dialog="openAddDialog"
              />
            </v-col>
            <v-col v-if="isTherapistOrParent" cols="12" sm="12" md="5" lg="4">
              <AddAccountButton
                id="add-student"
                :isTherapist="isTherapistOrParent"
                @open-add-dialog="openAddDialog"
              />
            </v-col>

            <v-col v-if="isSchool && hasGrade" cols="12" sm="12" md="5" lg="4">
              <OpenImportButton @open-import="openImportDialog" />
            </v-col>
            <v-col v-if="isTherapist" cols="12" sm="12" md="5" lg="4">
              <OpenImportButton @open-import="openImportDialog" />
            </v-col>
          </v-col>

          <v-col
            cols="12"
            class="table__search d-flex flex-wrap justify-center align-start"
            v-if="!isTherapistOrParent"
          >
            <v-col cols="12" sm="12" md="12" lg="4" class="mb-2">
              <PrintClassEvaluationButton :form="'a'" />
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="4" class="mb-2">
              <PrintClassEvaluationButton :form="'b'" />
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="4" class="mb-2">
              <PrintClassListButton :students="users" :grade="currentGrade" />
            </v-col>
          </v-col>
          <v-col
            cols="12"
            class="table__search d-flex flex-wrap justify-center align-start"
            v-else
          >
            <v-col cols="12" sm="12" md="8" lg="8" class="mb-2">
              <PrintClassListButton :students="users" :grade="currentGrade" />
            </v-col>
          </v-col>
        </v-col>
        <!-- INFO set classlevel or indivuallevel -->
        <SetClassLevel v-if="isSchool" @updated-level="added" />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="users"
        sort-by="studentId"
        :search="search"
        class="elevation-1 grey--text text--darken-3"
        :locale="'de-GE'"
        hide-default-footer
        disable-pagination
        id="student-table"
      >
        <!-- :footer-props="{
          itemsPerPageText: 'Zeilen pro Seite',
          pageText: '{0}-{1} von {2}',
          itemsPerPageAllText: 'Alle',
        }" -->
        <template v-slot:top>
          <v-row v-if="isSchool" class="ma-0 px-4 justify-center bg-black">
            <v-col cols="12" sm="6" class="mb-5 pa-0">
              <v-select
                id="grade"
                :label="$t('studentsTable.class')"
                v-model="chosenClass"
                :items="myClasses"
                item-text="name"
                item-value="id"
                dense
                outlined
                required
                background-color="white"
                hide-details
                return-object
              >
              </v-select>
            </v-col>
          </v-row>
          <EditDialog
            :show="showDialogEdit"
            :user="editedItem"
            :grade="currentGrade"
            :loading="loadEdit"
            @on-close="close"
            @on-save="save"
            :isStudent="true"
          />

          <DeleteDialog
            :show="showDialogDelete"
            :user="editedItem"
            @on-close="close"
            @on-delete="deleteItemConfirm"
          />
        </template>

        <!-- ACTIONS -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-col
            class="action-row d-flex flex-column justify-start align-start ma-0 pa-0"
          >
            <v-tooltip top allow-overflow open-delay="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="editItem(item)"
                  color="primary"
                  class="ma-0 pa-0"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="mr-2" color="primary"> mdi-pencil </v-icon>
                  {{ $t('edit') }}
                </v-btn>
              </template>
              <span>{{ $t('edit') }}</span>
            </v-tooltip>
            <v-tooltip top allow-overflow open-delay="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="printCode(item)"
                  :loading="loadSingle && loadingId === item._id"
                  color="primary"
                  class="ma-0 pa-0"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="mr-2" color="primary">
                    mdi-badge-account-horizontal
                  </v-icon>
                  {{ $t('teacher.print-code') }}
                </v-btn>
              </template>
              <span>{{ $t('teacher.print-code') }}</span>
            </v-tooltip>
            <DeleteTestButton v-if="isSchool" :student="item" />
            <v-tooltip top allow-overflow open-delay="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="deleteItem(item)"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  class="ma-0 pa-0"
                >
                  <v-icon color="primary" class="pr-2"> mdi-delete</v-icon>
                  {{ $t('delete-student') }}
                </v-btn>
              </template>
              <span>{{ $t('delete-student') }}</span>
            </v-tooltip>
          </v-col>
        </template>

        <!-- CLASS -->
        <template v-if="isTherapistOrParent" v-slot:[`item.grade`]="{ item }">
          <td v-if="item.grade">{{ item.grade }}</td>
          <td v-else>-</td>
        </template>

        <!-- FORMS -->
        <template v-slot:[`item.test`]="{ item }">
          <v-row
            v-if="isParent"
            class="d-flex flex-column justify-start align-start"
          >
            <EnableFormSwitch
              :id="item._id"
              :enabledForm="item.enableA === 'true'"
              :form="'a'"
              @toggled="added"
            />
            <EnableFormSwitch
              :id="item._id"
              :enabledForm="item.enableB === 'true'"
              :form="'b'"
              @toggled="added"
            />
          </v-row>
          <v-row v-else class="d-flex flex-column justify-start align-start">
            <v-btn
              color="primary"
              class="formbutton__form-a mb-3 disableMultipleClick"
              :disabled="item.doneA === 'true'"
            >
              <p class="mb-0 pa-0 btn__text">{{ $t('teacher.formA') }}</p>
              <v-icon
                right
                small
                :color="item.doneA === 'true' ? 'white' : 'white'"
                >{{
                  item.doneA === 'true' ? 'mdi-check-bold' : 'mdi-close-thick'
                }}
              </v-icon>
            </v-btn>

            <v-btn
              color="primary"
              class="formbutton__form-b disableMultipleClick"
              :disabled="item.doneA === 'false' || item.doneB === 'true'"
            >
              <p class="mb-0 pa-0 btn__text">{{ $t('teacher.formB') }}</p>
              <v-icon
                right
                small
                :color="item.doneB === 'true' ? 'white' : 'white'"
                >{{
                  item.doneB === 'true' ? 'mdi-check-bold' : 'mdi-close-thick'
                }}
              </v-icon>
            </v-btn>
          </v-row>
        </template>

        <!-- LEVEL -->
        <template v-if="isSchool" v-slot:[`item.level`]="{ item }">
          <!-- <ClassLevelSelect
            :student-id="item._id"
            :is-single="true"
            @updated-level="added"
          /> -->
          <v-col class="d-flex justify-start pa-0">
            <p class="text-subtitle-1 pl-md-8 ma-0">{{ item.level }}</p>
          </v-col>
        </template>

        <!-- EVALUATION -->
        <template v-slot:[`item.evaluation`]="{ item }">
          <v-row class="d-flex flex-column justify-start align-start">
            <EvaluationButton
              v-if="item.doneA === 'true'"
              :student="item"
              :form="'a'"
              class="mb-3"
            />
            <v-btn
              depressed
              color="transparent"
              v-else
              class="hidden-btn disabledButton"
            />

            <EvaluationButton
              v-if="item.doneB === 'true'"
              :student="item"
              :form="'b'"
            />
            <v-btn
              v-else
              depressed
              color="transparent"
              class="hidden-btn disabledButton"
            />
          </v-row>
        </template>
      </v-data-table>
      <PrintClassListButton :students="users" :grade="currentGrade" />
      <DeleteAllTestButton v-if="isSchool" class="my-6" />
      <DeleteAllStudentsButton
        v-if="!isTherapistOrParent"
        :students="users"
        @deleted="added"
      />
    </v-card>
  </v-col>
</template>

<script lang="ts">
import SchoolModule from '@/store/modules/SchoolModule';
import UserModule from '@/store/modules/UserModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import {
  Client,
  GetSchoolClassDto,
  UpdateUserDto,
  UserDto,
} from '../../../../api/types/api';
import SnackbarModule from '../../../../store/modules/SnackbarModule';
import AdminSnackbar from '../../base/AdminSnackbar.vue';
import DeleteAllStudentsButton from '../delete-all/DeleteAllStudentsButton.vue';
import AddAccountButton from './AddAccountButton.vue';
import AddPatientAccount from './AddPatientAccount.vue';
import AddStudentAccount from './AddStudentAccount.vue';
import ClassLevelSelect from './ClassLevelSelect.vue';
import DeleteAllTestButton from './delete-tests/DeleteAllTestButton.vue';
import DeleteTestButton from './delete-tests/DeleteTestButton.vue';
import DeleteDialog from './DeleteDialog.vue';
import EditDialog from './EditDialog.vue';
import EnableFormSwitch from './EnableFormSwitch.vue';
import EvaluationButton from './EvaluationButton.vue';
import GeneralInfoPanels from './GeneralInfoPanels.vue';
import ImportDialog from './ImportDialog.vue';
import OpenImportButton from './OpenImportButton.vue';
import PrintClassEvaluationButton from './PrintClassEvaluationButton.vue';
import PrintClassListButton from './PrintClassListButton.vue';
import SetClassLevel from './SetClassLevel.vue';

@Component({
  components: {
    AdminSnackbar,
    EditDialog,
    DeleteDialog,
    EvaluationButton,
    PrintClassListButton,
    GeneralInfoPanels,
    AddPatientAccount,
    AddStudentAccount,
    ImportDialog,
    OpenImportButton,
    AddAccountButton,
    PrintClassEvaluationButton,
    EnableFormSwitch,
    SetClassLevel,
    ClassLevelSelect,
    DeleteAllStudentsButton,
    DeleteTestButton,
    DeleteAllTestButton,
  },
})
export default class StudentsTable extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  @Prop() users!: UserDto[];

  disableFormA: string[] = [];
  disableFormB: string[] = [];
  loadingId = '';
  loadSingle = false;
  loadEdit = false;
  search = '';
  showDialogEdit = false;
  showDialogDelete = false;
  editedItem: UserDto | null = null;

  showImportDialog = false;
  showAddDialog = false;

  openImportDialog() {
    this.showImportDialog = true;
  }

  closeImportDialog() {
    this.showImportDialog = false;
  }

  openAddDialog() {
    this.showAddDialog = true;
  }

  closeAddDialog() {
    this.showAddDialog = false;
  }

  get myClasses(): GetSchoolClassDto[] {
    return this.userModule.myClasses;
  }

  get hasGrade(): boolean {
    return this.myClasses.length > 0;
  }

  get currentGrade(): string {
    return this.schoolModule.selectedClass?.name ?? '';
  }

  get chosenClass(): GetSchoolClassDto | null {
    return this.schoolModule.selectedClass;
  }

  set chosenClass(value: GetSchoolClassDto | null) {
    this.schoolModule.setChosenClass(value);
  }

  get ids(): number[] {
    return this.users.map((i) => i.studentId!);
  }

  get headers(): any {
    return this.isTherapistOrParent
      ? [
          { text: 'ID', value: 'studentId' },
          { text: 'Vorname', value: 'firstname' },
          { text: 'Nachname', value: 'lastname' },
          {
            text: 'Schulstufe',
            value: 'grade',
          },
          { text: 'Test', value: 'test', sortable: false },
          {
            text: 'Auswertung',
            value: 'evaluation',
            sortable: false,
          },
          {
            text: 'Aktionen',
            value: 'actions',
            sortable: false,
          },
        ]
      : [
          { text: 'ID', value: 'studentId' },
          { text: 'Vorname', value: 'firstname' },
          { text: 'Nachname', value: 'lastname' },
          {
            text: 'Schulstufe',
            value: 'level',
            sortable: false,
          },
          { text: 'Test', value: 'test', sortable: false },
          {
            text: 'Auswertung',
            value: 'evaluation',
            sortable: false,
          },
          {
            text: 'Aktionen',
            value: 'actions',
            sortable: false,
          },
        ];
  }

  get isTherapistOrParent(): boolean {
    return (
      this.schoolModule.clientType === Client.therapy ||
      this.schoolModule.clientType === Client.parent
    );
  }

  get isParent(): boolean {
    return this.schoolModule.clientType === Client.parent;
  }

  get isSchool(): boolean {
    return this.schoolModule.clientType === Client.school;
  }

  get isTherapist(): boolean {
    return this.schoolModule.clientType === Client.therapy;
  }

  editItem(user: UserDto): void {
    this.editedItem = user;
    this.showDialogEdit = true;
  }

  deleteItem(user: UserDto): void {
    this.editedItem = user;
    this.showDialogDelete = true;
  }

  async printCode(user: UserDto) {
    this.loadSingle = true;
    this.loadingId = user._id;
    try {
      await this.schoolModule.getSingleCode(user._id);
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.code.success').toString(),
        isSuccess: true,
      });
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.code.error').toString(),
        isSuccess: false,
      });
    } finally {
      this.loadSingle = false;
      this.loadingId = '';
    }
  }

  async deleteItemConfirm(user: UserDto): Promise<void> {
    try {
      await this.schoolModule.deleteUser(user._id);
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.students.delete.success').toString(),
        isSuccess: true,
      });
      this.added();
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.students.delete.error').toString(),
        isSuccess: false,
      });
    } finally {
      this.close();
    }
  }

  close(): void {
    this.showDialogEdit = false;
    this.showDialogDelete = false;
    this.editedItem = null;
  }

  async save(user: UpdateUserDto): Promise<void> {
    try {
      this.loadEdit = true;
      await this.schoolModule.updateUser({
        id: this.editedItem!._id,
        updateUser: user,
      });
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.students.edited.success').toString(),
        isSuccess: true,
      });
      this.close();
      this.added();
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.students.edited.error').toString(),
        isSuccess: false,
      });
    } finally {
      this.loadEdit = false;
    }
  }

  @Emit()
  added(): void {
    console.log();
  }
}
</script>

<style lang="scss" scoped>
.student-table {
  .v-data-table {
    line-height: 7;
    box-shadow: none;
  }

  &__action-item {
    height: 25% !important;
  }

  .formbutton {
    color: white !important;

    &__form-a {
      background-color: $button-form-a-active !important;

      &--chosen {
        background-color: $button-form-a-disabled !important;
        color: $text-primary !important;
      }
    }
    &__form-b {
      background-color: $button-form-b-active !important;

      &--chosen {
        background-color: $button-form-b-disabled !important;
        color: $text-primary !important;
      }
    }
  }
}

// .evaluation-flex {
//   gap: 12px;
// }

.hidden-btn {
  display: hidden;
  background-color: transparent !important;
}

.action-row {
  width: 100%;
}

.done-row {
  height: 100%;
}

.v-data-table::v-deep th {
  font-size: 16px !important;
  font-weight: 600;
}

.table {
  &__search {
    border-top: 1px solid lightgrey;
  }
}

.btn {
  &__text {
    margin-top: 1.75px !important;
  }
}

.levelRow {
  width: 80%;
}

// .class-level-row {
//   height: 80%;
// }
</style>

<style lang="scss">
tbody tr:nth-of-type(even) {
  background-color: $box-bg !important;
}

.v-data-table-header th {
  white-space: nowrap;
}
</style>
