<template>
  <v-dialog v-model="show" max-width="600px" persistent>
    <v-card>
      <v-card-title class="mt-2 justify-center">
        <p v-if="isTherapist" class="text-h5 font-weight-bold">
          {{ $t('therapist.edit-patient') }}
        </p>
        <p v-else class="text-h5 font-weight-bold">
          {{ $t('teacher.edit-student') }}
        </p>
      </v-card-title>
      <v-form @submit.prevent="save">
        <v-card-text>
          <v-container class="edit-student-form">
            <v-col cols="12">
              <v-text-field
                v-model="studentId"
                label="ID"
                id="id"
                :error-messages="numberErrors"
                @input="$v.studentId.$touch()"
                @blur="$v.studentId.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="firstname"
                label="Vorname"
                id="firstname"
                :error-messages="firstnameErrors"
                @input="$v.firstname.$touch()"
                @blur="$v.firstname.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="lastname"
                label="Nachname"
                id="lastname"
                :error-messages="lastnameErrors"
                @input="$v.lastname.$touch()"
                @blur="$v.lastname.$touch()"
              ></v-text-field>
            </v-col>
            <v-col v-if="isSchool" cols="12">
              <v-select
                v-model="level"
                :items="items"
                item-text="text"
                item-value="value"
                label="Schulstufe"
                id="level"
              ></v-select>
            </v-col>
            <v-col v-if="isTherapist" cols="12">
              <v-select
                v-model="grade"
                :items="classes"
                label="Klasse"
                id="grade"
                :error-messages="gradeErrors"
                @input="$v.grade.$touch()"
                @blur="$v.grade.$touch()"
              ></v-select>
            </v-col>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onClose">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            id="edit-student-button"
            color="green"
            dark
            :loading="loading"
            type="submit"
            >{{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import {
  minLength,
  minValue,
  numeric,
  required,
} from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';
import {
  Client,
  Role,
  UpdateUserDto,
  UserDto,
} from '../../../../api/types/api';
import UserModule from '../../../../store/modules/UserModule';
import SchoolModule from '../../../../store/modules/SchoolModule';

@Component({
  mixins: [validationMixin],
  validations: {
    firstname: { required, minLength: minLength(3) },
    lastname: { required, minLength: minLength(3) },
    studentId: { required, numeric, minValue: minValue(1) },
    grade: { required },
  },
})
export default class EditDialog extends Vue {
  @Prop() show!: boolean;
  @Prop() user!: UserDto;
  @Prop() loading!: boolean;

  userModule: UserModule = getModule(UserModule, this.$store);
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);

  firstname = '';
  lastname = '';
  studentId = 0;
  grade: string | null = null;
  level: number | null = null;

  get isTherapist(): boolean {
    return this.userModule.currentUser.role === Role.therapist;
  }

  get isSchool(): boolean {
    return this.schoolModule.clientType === Client.school;
  }

  get classes(): string[] {
    return this.schoolModule.classes!;
  }

  @Watch('show')
  onShowChanged(): void {
    if (this.show) {
      this.$v.$reset();
      this.firstname = this.user.firstname;
      this.lastname = this.user.lastname;
      this.studentId = this.user.studentId!;
      this.grade = this.user.grade ?? null;
      this.level = this.user.level ?? null;
    }
  }

  get password(): string {
    return this.user.studentPassword!;
  }

  items = [
    { value: '2', text: '2.Schulstufe' },
    { value: '3', text: '3.Schulstufe' },
    { value: '4', text: '4.Schulstufe' },
    { value: '5', text: '5.Schulstufe' },
    { value: '6', text: '6.Schulstufe' },
  ];

  save(): void {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      const user: UpdateUserDto = {
        grade: this.grade!,
        //INFO strange bug: is number but backend received string => extra casting
        studentId: parseInt(this.studentId!.toString()),
        firstname: this.firstname,
        lastname: this.lastname,
        level: this.isSchool && this.level ? +this.level : undefined,
        // studentPassword: this.password,
        // password: this.password,
      };
      this.onSave(user);
    } else {
      console.log('invalid');
    }
  }

  @Emit()
  onSave(user: UpdateUserDto) {
    return user;
  }

  @Emit()
  onClose(): void {
    console.log('');
  }

  get numberErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.studentId.$dirty) return errors;
    !this.$v.studentId.numeric && errors.push('Nur Zahlen erlaubt!');
    !this.$v.studentId.minValue && errors.push('Nur Zahlen > 0 erlaubt!');
    !this.$v.studentId.required && errors.push('Bitte ID angeben.');
    return errors;
  }

  get firstnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.firstname.$dirty) return errors;
    !this.$v.firstname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.firstname.required && errors.push('Bitte Vornamen angeben.');
    return errors;
  }
  get lastnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.lastname.$dirty) return errors;
    !this.$v.lastname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.lastname.required && errors.push('Bitte Nachnamen angeben.');
    return errors;
  }

  get passwordErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.password.$dirty) return errors;
    !this.$v.password.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.password.required && errors.push('Bitte Passwort angeben.');
    return errors;
  }
  get gradeErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.grade.$dirty) return errors;
    !this.$v.grade.required && errors.push('Bitte Schulstufe angeben.');
    return errors;
  }
}
</script>

<style scoped lang="scss"></style>
