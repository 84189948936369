<template>
  <v-btn
    color="primary"
    class="evaluation-button"
    :loading="loading"
    @click="generatePdf"
  >
    <v-icon class="mr-1">{{ buttonIcon }}</v-icon>
    <p class="pt-1 ma-0">{{ buttonText }}</p>
  </v-btn>
</template>

<script lang="ts">
import AdminSnackbar from '../../base/AdminSnackbar.vue';
import EvaluationTemplate from '../pdf-templates/EvaluationTemplate.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import { getModule } from 'vuex-module-decorators';
import { Prop } from 'vue-property-decorator';
import UserModule from '@/store/modules/UserModule';
import { UserDto } from '@/api/types';
import { SnackbarData } from '../../../../types';

@Component({
  components: { EvaluationTemplate, AdminSnackbar },
})
export default class EvaluationButton extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  @Prop() student!: UserDto;
  @Prop() form!: string;

  date = this.$t('evaluate').toString();
  loading = false;

  progress = 0;
  snackbar = false;
  isSuccess = false;
  message = '';

  onProgress(progress: number): void {
    this.progress = progress;
    if (this.progress === 100) {
      setTimeout(async () => {
        this.progress = 0;
      }, 1000);
    }
  }

  get buttonText(): string {
    if (this.form === 'a') {
      return this.student.downloadedA ?? this.date;
    } else {
      return this.student.downloadedB ?? this.date;
    }
  }

  get buttonIcon(): string {
    return this.buttonText === this.$t('evaluate').toString()
      ? 'mdi-chart-arc'
      : 'mdi-file-pdf-box';
  }

  get isSchoolClient(): boolean {
    return this.schoolModule.isSchoolClient;
  }

  async generatePdf(): Promise<void> {
    this.loading = true;
    try {
      await this.userModule.getEvaluationOfForm({
        form: this.form,
        id: this.student._id,
      });

      this.date = new Date().toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });

      // write current dowloaded date in db
      if (this.form === 'a') {
        await this.schoolModule.updateUser({
          updateUser: { downloadedA: this.date },
          id: this.student._id,
        });
      } else {
        await this.schoolModule.updateUser({
          updateUser: { downloadedB: this.date },
          id: this.student._id,
        });
      }

      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.pdf.success').toString(),
        isSuccess: true,
      });
    } catch (error: any) {
      if (!this.student?.level && this.isSchoolClient) {
        this.snackbarModule.showMessage({
          message: this.$i18n.t('snackbar.pdf.single-error').toString(),
          isSuccess: false,
        });
      } else {
        this.snackbarModule.showMessage({
          message: this.$i18n.t('snackbar.pdf.error').toString(),
          isSuccess: false,
        });
      }
    }
    this.loading = false;
  }

  showSnackbar(data: SnackbarData) {
    this.snackbar = true;
    this.isSuccess = data.isSuccess;
    this.message = data.message;
    setTimeout(async () => {
      this.snackbar = false;
    }, 2500);
  }
}
</script>

<style scoped lang="scss">
.loading-col {
  width: 100%;
}

.hidden {
  color: $primary;
}

.hideList {
  display: none !important;
}

.layout-container-pdf {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: -100vw;
  top: 0;
  z-index: -99999;
}
</style>
