<template>
  <v-col
    cols="12"
    class="teacher-dashboard d-flex flex-column justify-center align-center mb-4"
  >
    <SwitchViewForAdmin />
    <StudentDashboardHeadline v-if="isTherapist" :isTherapist="isTherapist" />
    <!-- <AdminButton :grade="grade" /> -->
    <StudentsTable :users="students" @added="fetchStudents" />
    <AdminSnackbar />
  </v-col>
</template>

<script lang="ts">
import SchoolModule from '@/store/modules/SchoolModule';
import UserModule from '@/store/modules/UserModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import {
  Client,
  GetSchoolClassDto,
  Role,
  UserDto,
} from '../../../api/types/api';
import AdminButton from '../base/AdminButton.vue';
import AdminSnackbar from '../base/AdminSnackbar.vue';
import SwitchViewForAdmin from '../teachers/table/buttons/SwitchViewForAdmin.vue';
import StudentDashboardHeadline from './StudentDashboardHeadline.vue';
import StudentsTable from './table/StudentsTable.vue';

@Component({
  components: {
    StudentDashboardHeadline,
    StudentsTable,
    AdminSnackbar,
    AdminButton,
    SwitchViewForAdmin,
  },
})
export default class StudentDashboard extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  showImportDialog = false;

  get teacher(): UserDto {
    return this.userModule.currentUser;
  }

  get selectedClass(): GetSchoolClassDto | null {
    return this.schoolModule.selectedClass;
  }

  get students(): UserDto[] {
    return this.isTherapist || this.isParent
      ? this.schoolModule.patients
      : this.schoolModule.myStudents;
  }

  get isTherapist(): boolean {
    return (
      (this.userModule.currentUser.role === Role.therapist ||
        this.userModule.currentUser.role === Role.admin) &&
      this.schoolModule.clientType === Client.therapy
    );
  }

  get isParent(): boolean {
    return this.userModule.currentUser.role === Role.parent;
  }

  checkIfChosenClassStillExists() {
    const chosenClass = this.selectedClass ?? this.teacher.classes?.[0];
    const teacherClassNames = this.teacher.classes?.map((c) => c.name);
    // INFO if teacher with admin rights changed class name => check if new class name is in teacher classes
    if (!teacherClassNames?.includes(chosenClass?.name)) {
      this.schoolModule.setChosenClass(this.teacher.classes?.[0]);
    } else {
      this.schoolModule.setChosenClass(chosenClass);
    }
  }

  async created(): Promise<void> {
    this.checkIfChosenClassStillExists();
    // INFO fetching students via watcher
    // await this.fetchStudents();
  }

  async fetchStudents(): Promise<void> {
    this.isTherapist || this.isParent
      ? await this.schoolModule.getPatients()
      : await this.schoolModule.getClassStudents(this.selectedClass?.name);
  }

  @Watch('selectedClass', { immediate: true })
  async onSelectedClassChanged(): Promise<void> {
    this.checkIfChosenClassStillExists();
    await this.fetchStudents();
  }
}
</script>

<style scoped lang="scss"></style>
