<template>
  <v-row cols="12" class="ma-0">
    <v-tooltip top allow-overflow open-delay="1000">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          @click="openDialog"
          color="primary"
          v-bind="attrs"
          v-on="on"
          class="ma-0 pa-0"
        >
          <v-icon color="primary" class="pr-2"> mdi-file-remove</v-icon>
          {{ $t('manageTests.delete.button') }}
        </v-btn>
      </template>
      <span>{{ $t('manageTests.delete.info') }}</span>
    </v-tooltip>
    <DeleteTestDialog
      :show="showDialog"
      :name="student.firstname + ' ' + student.lastname"
      @confirm-delete="deleteTestResults"
      @close="closeDialog"
    />
  </v-row>
</template>

<script lang="ts">
import { UserDto } from '@/api/types';
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import DeleteTestDialog from './DeleteTestDialog.vue';

@Component({
  components: { DeleteTestDialog },
})
export default class DeleteTestButton extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  @Prop()
  student!: UserDto;

  showDialog = false;

  openDialog() {
    this.showDialog = true;
  }

  closeDialog() {
    this.showDialog = false;
  }

  async deleteTestResults() {
    try {
      await this.schoolModule.deleteTestResultFromStudent({
        schoolId: this.student.schoolId.toString(),
        studentId: this.student._id,
      });
      await this.schoolModule.getClassStudents(this.student.grade);
      this.snackbarModule.showMessage({
        message: this.$i18n.t('manageTests.delete.success.single').toString(),
        isSuccess: true,
      });
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('manageTests.delete.error').toString(),
        isSuccess: false,
      });
    }
  }
}
</script>

<style scoped lang="scss"></style>
