<template>
  <section class="pdf-content">
    <section class="pdf-content__items pdf-item">
      <v-row class="pdf-list">
        <v-col cols="6" class="name-tag">
          <p class="norddruck pdf-content__url">
            {{ this.student.firstname }}
            {{ this.student.lastname }}
            {{ this.student.grade }}
            {{ this.form }}
          </p>
        </v-col>
      </v-row>
    </section>
  </section>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { FormResultDto, UserDto } from '../../../../api/types';

@Component({})
export default class EvaluationTemplate extends Vue {
  @Prop() student!: UserDto;
  @Prop() grade!: string;
  @Prop() form!: string;
  @Prop() data?: FormResultDto;
}
</script>

<style scoped lang="scss"></style>
