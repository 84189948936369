<template>
  <v-col
    cols="12"
    class="set-class-level d-flex flex-column justify-center align-center pt-0"
  >
    <v-row
      cols="12"
      class="set-class-level__select d-flex justify-center align-start flex-wrap pa-0 ma-0"
    >
      <v-col cols="12" md="6" class="pa-0 justify-self-end d-flex">
        <p
          class="text-subtitle-1 font-weight-bold ma-0 pt-2 mr-2 text--darken-2"
        >
          {{ $t('classLevel.info') }}
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="6" class="pa-0 justify-self-start">
        <ClassLevelSelect @updated-level="updatedLevel" />
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0 mt-2 mb-4">
      <v-col>

        <p class="text-body-1 ma-0 text-center text--darken-2">
          {{ $t('classLevel.evaluation') }}
        </p>
        <p class="text-body-1 ma-0 text-center text--darken-2">
          {{ $t('classLevel.single-student') }}
        </p>
      </v-col>
    </v-row>
  </v-col>
</template>

<script lang="ts">
import ClassLevelSelect from './ClassLevelSelect.vue';
import ClassListTemplate from '../pdf-templates/ClassListTemplate.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit } from 'vue-property-decorator';

@Component({
  components: { ClassListTemplate, ClassLevelSelect },
})
export default class SetClassLevel extends Vue {

  @Emit()
  updatedLevel() {
    console.log();
  }
}
</script>

<style scoped lang="scss"></style>
