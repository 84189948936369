<template>
  <v-tooltip top open-delay="1000">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        block
        color="primary"
        class="class-list-button"
        @click="generatePdf"
        v-bind="attrs"
        v-on="on"
        :loading="loading"
      >
        <v-icon left dark>mdi-file-pdf-box </v-icon>
        <p class="pa-0 ma-0 mr-2 mt-1" align="center">
          {{ $t('teacher.codes') }}
        </p>
      </v-btn>
    </template>
    <span>{{ $t('teacher.download-classList-info') }}</span>
  </v-tooltip>
</template>

<script lang="ts">
import ClassListTemplate from '../pdf-templates/ClassListTemplate.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import SchoolModule from '@/store/modules/SchoolModule';
import { getModule } from 'vuex-module-decorators';
import { Prop } from 'vue-property-decorator';
import { UserDto } from '@/api/types';
import SnackbarModule from '@/store/modules/SnackbarModule';

@Component({
  components: { ClassListTemplate },
})
export default class PrintClassListButton extends Vue {
  @Prop() students!: UserDto[];
  @Prop() grade!: string;

  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  progress = 0;
  loading = false;

  async generatePdf(): Promise<void> {
    this.loading = true;
    try {
      await this.schoolModule.getClasslist(`Zugangsliste.pdf`);
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.code.success').toString(),
        isSuccess: true,
      });
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.code.error').toString(),
        isSuccess: false,
      });
    }
    this.loading = false;
  }
}
</script>

<style scoped lang="scss">
.loading-col {
  width: 100%;
}

.hidden {
  color: $primary;
}

.hideList {
  display: none !important;
}

.layout-container-pdf {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: -100vw;
  top: 0;
  z-index: -99999;
}
</style>
