<template>
  <v-dialog :value="dialog" persistent max-width="800">
    <v-col class="import-dialog pa-0 ma-0">
      <v-col cols="12" class="d-flex justify-end align-center px-0">
        <v-btn text @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-col>

      <p class="text-center text-h5 text-sm-h4 text-md-h4 primary--text mb-0">
        {{ $t('teacher.import-classList') }}
      </p>

      <v-col cols="12" class="d-flex justify-center align-center">
        <CsvImportStudents
          :isTherapist="isTherapist"
          :grade="grade"
          :ids="ids"
          :schoolId="teacher.schoolId"
          @importStudents="imported"
          :show="dialog"
        />
      </v-col>
    </v-col>
  </v-dialog>
</template>

<script lang="ts">
import SchoolModule from '@/store/modules/SchoolModule';
import UserModule from '@/store/modules/UserModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import CsvImportStudents from './CsvImportStudents.vue';
import { UserDto } from '@/api/types';

@Component({
  components: {
    CsvImportStudents,
  },
})
export default class ImportDialog extends Vue {
  @Prop() dialog!: boolean;
  @Prop() ids!: number[];
  @Prop() isTherapist!: boolean;

  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  students: UserDto[] = [];
  snackbar = false;
  isSuccess = false;
  message = '';

  get teacher(): UserDto {
    return this.userModule.currentUser;
  }

  get grade(): string {
    return this.schoolModule.selectedClass?.name ?? 'no-class';
  }

  get myStudents(): UserDto[] {
    return this.students;
  }

  @Emit()
  close(): void {
    console.log();
  }

  @Emit()
  imported(): void {
    this.close();
  }
}
</script>

<style scoped lang="scss">
.import-dialog {
  background-color: $background;
}
</style>
