<template>
  <v-col class="d-flex align-center pa-0">
    <v-row
      v-if="isSingle"
      class="single-select-row ma-0 pa-0 d-flex flex-column pt-md-0"
    >
      <v-col cols="12" class="pa-0 mb-2">
        <v-select
          v-model="singleLevel"
          :label="label"
          :placeholder="placeholder"
          class="single-select-level"
          id="grade"
          :items="items"
          item-text="text"
          item-value="value"
          hide-details
          outlined
          dense
          required
          :loading="loading"
          background-color="white"
        ></v-select>
      </v-col>
      <v-col cols="12" class="pa-0 d-flex justify-start align-center">
        <v-tooltip bottom open-delay="1000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="updateLevel"
              color="primary"
              :loading="loading"
              block
              v-bind="attrs"
              v-on="on"
            >
              <v-icon v-if="showSuccess">mdi-check-circle-outline </v-icon>
              <v-icon v-else-if="showError" class="ml-1" color="error" small
                >mdi-alert
              </v-icon>
              <v-icon v-else>mdi-content-save</v-icon>
            </v-btn>
          </template>
          <span>{{ $t(`classLevel.save`) }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-col cols="12" class="pa-0 d-flex" v-else>
      <v-select
        v-model="level"
        :label="label"
        :placeholder="placeholder"
        id="grade"
        data-testid="class-level"
        :items="items"
        item-text="text"
        item-value="value"
        outlined
        dense
        required
        background-color="white"
      ></v-select>
      <v-btn
        class="ml-2"
        color="primary"
        data-testid="save-level-button"
        @click="setClassLevel"
        :loading="loading"
      >
        <v-icon class="mr-2">mdi-content-save</v-icon>
        <p class="ma-0 pt-1">{{ $t('save') }}</p>
      </v-btn>
    </v-col>
  </v-col>
</template>

<script lang="ts">
import ClassListTemplate from '../pdf-templates/ClassListTemplate.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import SchoolModule from '@/store/modules/SchoolModule';
import { getModule } from 'vuex-module-decorators';
import { Prop, Emit } from 'vue-property-decorator';
import SnackbarModule from '@/store/modules/SnackbarModule';
import { UserDto } from '@/api/types';

@Component({
  components: { ClassListTemplate },
})
export default class ClassLevelSelect extends Vue {
  @Prop({ default: false }) isSingle?: boolean;
  @Prop() studentId?: string;

  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  level: number | null = null;
  sLevel: number | null = this.student?.level ? +this.student.level : null;

  items = [
    { value: 2, text: this.isSingle ? '2' : '2.Schulstufe' },
    { value: 3, text: this.isSingle ? '3' : '3.Schulstufe' },
    { value: 4, text: this.isSingle ? '4' : '4.Schulstufe' },
    { value: 5, text: this.isSingle ? '5' : '5.Schulstufe' },
    { value: 6, text: this.isSingle ? '6' : '6.Schulstufe' },
  ];

  label = this.isSingle ? '' : this.$t('classLevel.level').toString();
  placeholder = this.isSingle ? '' : this.$t('classLevel.label').toString();

  loading = false;
  showSuccess = false;
  showError = false;

  get student(): UserDto | undefined {
    return this.schoolModule.myStudents?.find(
      (student) => student._id === this.studentId,
    );
  }

  get students(): UserDto[] {
    return this.schoolModule.myStudents;
  }

  get singleLevel(): number | null {
    return this.student?.level ? +this.student.level : null;
  }

  set singleLevel(level: number | null) {
    this.sLevel = level;
  }

  async updateLevel() {
    try {
      this.loading = true;
      if (this.sLevel) {
        // TODO how to change on error
        // throw new Error('No level selected');
        await this.schoolModule.updateUser({
          id: this.student!._id,
          updateUser: {
            level: this.sLevel!,
          },
        });
        this.showSuccess = true;
        setTimeout(() => {
          this.showSuccess = false;
        }, 1500);
      }
    } catch (error) {
      // TODO handle error on sinlge setting
      this.singleLevel = this.student?.level ? +this.student.level : null;
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 1500);
      this.snackbarModule.showMessage({
        message: this.$i18n.t('classLevel.single-error').toString(),
        isSuccess: false,
        time: 6000,
      });
    } finally {
      this.updatedLevel();
      this.loading = false;
    }
  }

  async setClassLevel() {
    try {
      this.loading = true;
      if (this.students?.length !== 0) {
        for (const stud of this.students!) {
          await this.schoolModule.updateUser({
            id: stud._id,
            updateUser: {
              level: this.level!,
            },
          });
        }
        this.snackbarModule.showMessage({
          message: this.$i18n.t('classLevel.success').toString(),
          isSuccess: true,
        });
      }
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('classLevel.error').toString(),
        isSuccess: false,
      });
    } finally {
      this.updatedLevel();
      this.loading = false;
    }
  }

  @Emit()
  updatedLevel() {
    console.log();
  }

  get levelErrors(): string[] {
    const required = this.$t('classLevel.required').toString();
    const errors: string[] = [];
    if (!this.$v.level.$dirty) return errors;
    !this.$v.level.required && errors.push(required);
    return errors;
  }
}
</script>

<style scoped lang="scss">
// .single-select-level {
//   min-width: 80px;
//   max-width: 80px;
// }

.single-select-row {
  width: 100px;
  max-width: 100px;
  @media screen and (min-width: 600px) {
    width: 60px;
    max-width: 80px;
  }
}
</style>
